import { postMessagePWA, requestTaskHistoryToExcel, poweryardArrivalHistory, requestGateHistoryToExcel } from "../../NetworkHelpers";
import MomentDisplay from "../../assets/components/MomentDisplay";
import { getFormattedDwellDateString } from "../../assets/components/StaticDurationDisplay";
import moment from "moment";
import React, { Component } from "react";
import { PaginationFilterTable } from "../../common/PaginationFilterTable";
// import { PaginationFilterTableArrival } from "../../common/PaginationFilterTableArrival";
import { getTemp, isF } from "./Tasks";
import * as PropTypes from "prop-types";
import TableDateRangePicker from "../../common/TableDateRangePicker";
import {
  endOfDay, getLocalStorageName, getTableSize, startOfDay, startOfDayEST,
  endOfDayEST
} from "../../common/utilities";
import { Button } from "reactstrap";
import { showError, showInfo } from "../../MainApp";
import * as uuid from 'uuid';
import AttachmentsModal from "../../common/poweryard/AttachmentsModal";
import SMSModal from "../../common/poweryard/SMSModal";
import ShowCustomList from "../../common/poweryard/ShowCustomList";
import { PaginationFilterTableArrival } from "../../common/PaginationFilterTableArrival";
class ArrivalHistory extends Component {

  tableColumns = [
    {
      Header: 'Action',
      filterable: false,
      default: true,
      sortable: false,
      disableControls: true,
      width: 160,
      Cell: (row) => {
        return <>
          <Button
            title="Chat"
            aria-label="Chat"
            onClick={() => this.setState({ isOpen: true, selectedArrival: row.original, })}>
            <i class="fas fa-sms "  ></i>
          </Button> &nbsp;
          &nbsp;
          <Button
            color={row.original.totalAttachments == "0" ? "secondary" : "primary"}
            title="Attachments"
            aria-label="Attachments"
            disabled={row.original.totalAttachments == "0"}
            onClick={() => this.setState({ isOpenAttachment: true, selectedArrival: row.original })}>
            <i class="fa fa-paperclip "  ></i>

          </Button> &nbsp;
          <Button
            title="Show Custom Fields"
            aria-label="Show Custom Fields"
            // disabled={row.original.totalAttachments == "0" }
            onClick={() => this.setState({ isOpenList: true, selectedArrival: row.original })}>
            <i class="fa fa-list"></i>

          </Button>
        </>;

      }
    },
    // {
    //   Header: 'Attachments',
    //   accessor: 'totalAttachments',
    //   filterable: false,
    //   default: true,
    //   sortable: false,
    //   disableControls: true,
    //   Cell: (row) => {
    //     return <>

    //       <Button
    //       color={ row.original.totalAttachments == "0" ? "secondary":"primary"}
    //         title="Files"
    //         aria-label="Files"
    //         disabled={row.original.totalAttachments == "0" }
    //         onClick={() => this.setState({ isOpenAttachment: true, selectedArrival: row.original })}>
    //         <i class="fa fa-paperclip "  ></i>

    //       </Button> 
    //     </>

    //   }
    // },
    {
      accessor: 'arrivalID',
      Header: 'Arrival ID',
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: 'locationName',
      Header: 'Location Name',
      default: false,
      filterable: true,
      sortable: true,
    },
    {
      accessor: 'locationCode',
      Header: 'Location ID',
      default: false,
      filterable: true,
      sortable: true,
    },

    {
      accessor: 'eta',
      Header: 'ETA',
      default: false,
      filterable: true,
      sortable: true,
      Cell: row => {
        let arr = row.original;
        if (arr.eta) {
          return <MomentDisplay date={arr.eta + "Z"}
            timezone={arr.timezone && arr.timezone} />;
        } else {
          return null;
        }
      }
    },
    {
      accessor: 'createdDate',
      Header: 'Created Date',
      default: true,
      filterable: false,
      sortable: true,
      Cell: row => {
        let arr = row.original;
        if (arr.createdDate) {
          return <MomentDisplay date={arr.createdDate + "Z"}
            timezone={arr.timezone} />;
        } else {
          return '';
        }
      }
    },
    {
      accessor: 'arrivalendtime',
      Header: 'Arrival Time',
      default: false,
      filterable: false,
      sortable: true,
      Cell: row => {
        let arr = row.original;
        if (arr.arrivalendtime) {
          return <MomentDisplay date={arr.arrivalendtime + "Z"}
            timezone={arr.timezone} />;
        } else {
          return null;
        }
      }
    },
    {
      accessor: 'currentEventStatus',
      Header: 'Event Status',
      default: false,
      filterable: true,
      sortable: true,
      Cell: (row => {

        if (row.original.currentEventStatus === "All") {
          return <>{this.tableRef}</>
        }
        if (!row.original.currentEventStatus) return "";
        return <>
          {row.original.currentEventStatus.charAt(0).toUpperCase() + row.original.currentEventStatus.slice(1)}
        </>;
      }),
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.eventStatusFilterRef}
            onChange={event => {
              this.setState({ eventStatusFilter: event.target.value });
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.eventStatusFilter ? this.state.eventStatusFilter : undefined}
          >
            <option value=''>All</option>
            <option value="complete">Complete</option>
            <option value="depart">Depart</option>


          </select>
        </>

    },
    {
      accessor: 'firstname',
      Header: 'Driver First ',
      default: true,
      filterable: true,
      sortable: true,
      // className: "clipboardShow",
      // Cell: (row => {
      //     if (row.original.firstname) {
      //       return (
      //         <ClipboardClickable
      //           id={"ID_" + uuid.v4()}
      //           clickClass={"hide"}
      //           value={row.original.firstname}
      //         >
      //           {row.original.firstname}
      //         </ClipboardClickable>
      //       )
      //     } else {
      //       return null;
      //     }
      //   })

    },
    {
      accessor: 'lastname',
      Header: 'Driver Last',
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: 'trailername',
      Header: 'Carrier Name',
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: 'trucknumber',
      Header: 'Truck #',
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: 'cancelUsername',
      Header: 'Canceled By',
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: 'cancelcomment',
      Header: 'Canceled Comment',
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: 'cancelUserdatetime',
      Header: 'Canceled Datetime',
      default: true,
      filterable: true,
      sortable: true,
      Cell: row => {
        let arr = row.original;
        if (arr.cancelUserdatetime) {
          return <MomentDisplay date={arr.cancelUserdatetime + "Z"}
            timezone={arr.timezone} />;
        } else {
          return '';
        }
      }
    },
    {
      accessor: 'arrivalappt',
      Header: 'Inbound Appt',
      default: true,
      filterable: false,
      sortable: true,
      Cell: row => {
        let arr = row.original;
        if (arr.arrivalappt) {
          return <MomentDisplay date={arr.arrivalappt + "Z"}
            timezone={arr.timezone} />;
        } else {
          return '';
        }
      }
    },
    {
      accessor: 'arrivalreference',
      Header: 'Inbound ' + (this.props.user.company.shipmentIdDisplay ? this.props.user.company.shipmentIdDisplay : 'Ref') + ' #',
      default: true,
      filterable: true,
      operator: 'GT',
      sortable: true,

    },
    {
      accessor: 'arrivaltrailername',
      Header: 'Inbound Trlr Name',
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: 'arrivaltrailertypename',
      Header: 'Inbound Trlr Type',
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: 'arrivaltrailernumber',
      Header: 'Inbound Trlr #',
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: 'arrivalsealed',
      Header: 'Inbound Sealed',
      default: false,
      filterable: false,
      sortable: false,
      Cell: (row => {
        if (row.original.arrivalsealno && row.original.arrivalsealno !== "" && row.original.arrivalsealno.toLowerCase() !== "n/a") {
          return ("Yes")
        } else {
          return ("No")
        }
      })
    },
    {
      accessor: 'arrivalsealno',
      Header: 'Inbound Seal #',
      default: false,
      filterable: false,
      sortable: false,
    },
    {
      accessor: 'arrivalstatus',
      Header: 'Inbound Trlr Status',
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row => {
        if (row.original.arrivalstatus === "All") {
          return <>{this.tableRef}</>
        }
        if (!row.original.arrivalstatus) return "";
        return <>
          {row.original.arrivalstatus.charAt(0).toUpperCase() + row.original.arrivalstatus.slice(1)}
        </>;
      }),
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.arrivalstatusFilterRef}
            onChange={event => {
              this.setState({ arrivalStatusFilter: event.target.value },
              );
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.arrivalStatusFilter ? this.state.arrivalStatusFilter : undefined}
          >
            <option value=''>All</option>
            <option value="Bobtail">Bobtail</option>
            <option value="Loaded">Loaded</option>
            <option value="Empty">Empty</option>

          </select>
        </>
    },
    {
      accessor: 'arrivalplan',
      Header: 'Inbound Plan',
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row => {
        if (row.original.arrivalplan === "All") {
          return <>{this.tableRef}</>
        }
        if (!row.original.arrivalplan) return "";
        return <>
          {row.original.arrivalplan.charAt(0).toUpperCase() + row.original.arrivalplan.slice(1)}
        </>;
      }),
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.arrivalplanFilterRef}
            onChange={event => {
              this.setState({ arrivalPlanFilter: event.target.value });
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.arrivalPlanFilter ? this.state.arrivalPlanFilter : undefined}
          >

            <option value=''>All</option>
            <option value="Hook">Hook</option>
            <option value="live">Live</option>
            <option value="drop">Drop</option>

          </select>
        </>
    },
    {
      accessor: 'departureappt',
      Header: 'Outbound Appt',
      default: true,
      filterable: false,
      sortable: true,
      Cell: row => {
        let arr = row.original;
        if (arr.departureappt) {
          return <MomentDisplay date={arr.departureappt + "Z"}
            timezone={arr.timezone} />;
        } else {
          return '';
        }
      }
    },
    {
      accessor: 'departurereference',
      Header: 'Outbound ' + (this.props.user.company.shipmentIdDisplay ? this.props.user.company.shipmentIdDisplay : 'Ref') + '#',
      default: true,
      filterable: true,
      sortable: true,

    },
    {
      accessor: 'departuretrailername',
      Header: 'Outbound Trlr Name',
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: 'departuretrailertypename',
      Header: 'Outbound Trlr Type',
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: 'departuretrailerno',
      Header: 'Outbound Trlr # ',
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: 'departuresealed',
      Header: 'Outbound Sealed',
      default: false,
      filterable: false,
      sortable: false,
      Cell: (row => {
        if (row.original.departuresealno && row.original.departuresealno !== "" && row.original.departuresealno.toLowerCase() !== "n/a") {
          return ("Yes")
        } else {
          return ("No")
        }
      })
    },
    {
      accessor: 'departuresealno',
      Header: 'Outbound Seal #',
      default: false,
      filterable: false,
      sortable: false,
    },
    {
      accessor: 'departurestatus',
      Header: 'Outbound Trlr Status',
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row => {
        if (row.original.departurestatus === "All") {
          return <>{this.tableRef}</>
        }
        if (!row.original.departurestatus) return "";
        return <>
          {row.original.departurestatus.charAt(0).toUpperCase() + row.original.departurestatus.slice(1)}
        </>;
      }),
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.departurestatusFilterRef}
            onChange={event => {
              this.setState({ departureStatusFilter: event.target.value });
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.departureStatusFilter ? this.state.departureStatusFilter : undefined}
          >
            <option value=''>All</option>
            <option value="Bobtail">Bobtail</option>
            <option value="Loaded">Loaded</option>
            <option value="Empty">Empty</option>
          </select>
        </>

    },
    {
      accessor: 'departureplan',
      Header: 'Outbound Plan',
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row => {
        if (row.original.departurestatus === "All") {
          return <>{this.tableRef}</>
        }
        if (!row.original.departureplan) return "";
        return <>
          {row.original.departureplan.charAt(0).toUpperCase() + row.original.departureplan.slice(1)}
        </>;
      }),
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.departureplanFilterRef}
            onChange={event => {
              this.setState({ departurePlanFilter: event.target.value });
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.departurePlanFilter ? this.state.departurePlanFilter : undefined}
          >
            <option value=''>All</option>
            <option value="Hook">Hook</option>
            <option value="live">Live</option>
            <option value="drop">Drop</option>
          </select>
        </>
    },


    {
      accessor: 'departureendtime',
      Header: 'Departure Time',
      default: false,
      filterable: true,
      sortable: true,
      width: 120,
      Cell: row => {
        let arr = row.original;
        if (arr.departureendtime) {
          return <MomentDisplay date={arr.departureendtime + "Z"}
            timezone={arr.timezone} />;
        } else {
          return '';
        }
      }

    },
    {
      accessor: 'dwelltime',
      Header: 'Dwell Time',
      default: false,
      filterable: true,
      sortable: true,
      width: 120

    },
    {
      accessor: 'checkinUsername',
      Header: 'Check-In User',
      default: false,
      filterable: false,
      sortable: true,
    },
    {
      accessor: 'checkinDatetime',
      Header: 'Check-In DateTime',
      default: false,
      filterable: false,
      sortable: true,
      Cell: row => {
        let arr = row.original;
        if (arr.checkinDatetime) {
          return <MomentDisplay date={arr.checkinDatetime + "Z"}
            timezone={this.props.currentLocation && this.props.currentLocation.timezone} />;
        } else {
          return '';
        }
      }
    },
    {
      accessor: 'completedUsername',
      Header: 'Completed Username',
      default: false,
      filterable: false,
      sortable: true,
      Cell: row => {
        let arr = row.original;
        console.log("cellll " + arr.departUsername);
        if (arr.completedUsername == undefined || arr.completedUsername==null || arr.completedUsername=='') {
          return arr.departUsername;
        }
        else {
          return arr.completedUsername;
        }
      }
    },
    {
      accessor: 'completedDatetime',
      Header: 'Completed DateTime',
      default: false,
      filterable: false,
      sortable: true,
      Cell: row => {
        let arr = row.original;
        if (arr.completedDatetime) {
          return <MomentDisplay date={arr.completedDatetime + "Z"}
            timezone={this.props.currentLocation && this.props.currentLocation.timezone} />;
        } else if (arr.departureendtime) {
          return <MomentDisplay date={arr.departureendtime + "Z"}
            timezone={this.props.currentLocation && this.props.currentLocation.timezone} />;
        }
        else {
          return '';
        }
      }
    },
    {
      accessor: 'checkOutUsername',
      Header: 'Check-Out User',
      default: false,
      filterable: false,
      sortable: true,
    },
    {
      accessor: 'checkOutDatetime',
      Header: 'Check-Out DateTime',
      default: false,
      filterable: false,
      sortable: true,
      Cell: row => {
        let arr = row.original;
        if (arr.checkOutDatetime) {
          return <MomentDisplay date={arr.checkOutDatetime + "Z"}
            timezone={this.props.currentLocation && this.props.currentLocation.timezone} />;
        } else {
          return '';
        }
      }
    },
  ]

  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.sealedFilterRef = React.createRef();
    this.taskStatusFilterRef = React.createRef();
    const parse = JSON.parse(localStorage.getItem(getLocalStorageName("poweryard-arrival-history", props.user)), this.mapFromJson);
    // const parse = JSON.parse(localStorage.getItem("poweryard-task-history"));
    if (parse) {
      this.state = {
        name: null,
        previousSearchRequest: {
          sorted: [{ id: "createdDate", desc: "true" }],
          filtered: [
            { id: 'LocationUuid', value: this.props.currentLocation ? this.props.currentLocation.uuid : "null" },
            { id: "start", value: startOfDay(new Date(), 7), operator: "GT" },
            { id: "end", value: endOfDay(new Date()), operator: "LT" },
            { id: "dateFilterField", value: "eta", operator: "EQ" },
          ],
        },
        sealedFilter: parse.sealedFilter,
        taskStatusFilter: parse.taskStatusFilter,
        filterStartDate: startOfDayEST(new Date()),
        filterEndDate: endOfDayEST(new Date()),
        filterDateType: parse.filterDateType,
        dateFilterEnabled:true,
        tableSize: parse.tableSize,
        autoRefreshEnabled: parse.autoRefreshEnabled,
        eventStatus: parse.eventStatus,
      };
    } else {
      this.state = {
        previousSearchRequest: {
          sorted: [{ id: "createdDate", desc: "true" }],
          filtered: [
            { id: 'OrganizationUuid', value: this.props.user.company ? this.props.user.company.uuid : "null" },
            { id: 'LocationUuid', value: this.props.currentLocation ? this.props.currentLocation.uuid : "null" },
            { id: "start",  value: startOfDay(new Date(), 7), operator: "GT" },
            { id: "end", value: endOfDay(new Date()), operator: "LT" },
            { id: "dateFilterField", value: "createdDate", operator: "EQ" }]
        },
        filterStartDate: startOfDayEST(new Date()),
        filterEndDate: endOfDayEST(new Date()),
        filterDateType: 'createdDate',
        tableSize: { height: "500px", width: "800px" },
        dateFilterEnabled: true,
        autoRefreshEnabled: false,
        eventStatus: ''
      };
    }
    if (!this.state.filterEndDate) {
      this.state.filterEndDate = endOfDay(new Date());
    }
    if (!this.state.filterStartDate) {
      this.state.filterStartDate = startOfDay(new Date(new Date().setDate(new Date().getDate() - 7)));
    }
    if (!this.state.filterDateType) {
      this.state.filterDateType = "createdDate";
    }
    if (!this.state.tableSize) {
      this.state.tableSize = { height: "500px", width: "800px" };
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.calculateTableSize.bind(this));
    window.scrollTo(0, 0);
    this.calculateTableSize();
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
    if (isTablet === true) {
      const datePickers = document.getElementsByClassName("react-datepicker__input-container");
      Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateTableSize.bind(this));
    localStorage.setItem(getLocalStorageName("poweryard-arrival-history", this.props.user), JSON.stringify({
      sealedFilter: this.state.sealedFilter,
      taskStatusFilter: this.state.taskStatusFilter,
      previousSearchRequest: this.state.previousSearchRequest,
      filterStartDate: this.state.filterStartDate,
      filterEndDate: this.state.filterEndDate,
      filterDateType: this.state.filterDateType,
      dateFilterEnabled: this.state.dateFilterEnabled,
    }))
  }
 
  savePageState() {
    localStorage.setItem(getLocalStorageName("poweryard-arrival-history", this.props.user), JSON.stringify({
      previousSearchRequest: this.state.previousSearchRequest,
      taskStatusFilter: this.state.taskStatusFilter,
      filterStartDate: this.state.filterStartDate,
      filterEndDate: this.state.filterEndDate,
      filterDateType: this.state.filterDateType,
      dateFilterEnabled: this.state.dateFilterEnabled,
      autoRefreshEnabled: this.state.autoRefreshEnabled,
    }));
  }
  exportTaskHistory() {

    if (this.state.previousSearchRequest) {
      requestTaskHistoryToExcel(this.state.previousSearchRequest, (res) => {
        let a = res.message;
        if (a && a.includes("https://e4score-ezcheckin")) {
          window.location.href = a;
        } else {
          showError("We are not able to generate the excel file at this time.  Please try again later.");
        }
        // showInfo("We are currently generating the Excel file and you will receive it via email shortly.");
      }, (e) => {
        console.log(e);
        showError("We are not able to generate the excel file at this time.  Please try again later.");
      });
    }
  }
  calculateTableSize = () => {
    let size = getTableSize("power-yard-task-history-page", "power-yard-task-history-header");
    this.setState({ tableSize: { height: size.height, width: size.width } });
  }
  render() {
    return <div className="column-wrapper text-left" id="power-yard-task-history-page">

      <div className="page-header" id={"power-yard-task-history-header"}>
        <h1>Gate History</h1>
      </div>
      <SMSModal
        open={this.state.isOpen}
        hideChat={true}
        selectedArrival={this.state.selectedArrival}
        currentCompany={this.props.user.company.name}
        currentLocation={this.props.currentLocation}
        onAdd={(message) => {
          // msg
          postMessagePWA(message, () => {
            this.setState({ isOpen: false });
          }, () => {
          });
        }}
        onClose={() => this.setState({ isOpen: false })}
      />
      <AttachmentsModal
        hideChat={false}
        open={this.state.isOpenAttachment}
        selectedArrival={this.state.selectedArrival}
        currentLocation={this.props.currentLocation.name !== "" ? this.props.currentLocation.name : this.props.currentLocation.nickname}
        // onAdd={(message) => {

        //   postMessagePWA(message, () => {
        //     this.setState({ isOpenAttachment: false });
        //   }, () => {
        //   });
        // }}
        onClose={() => this.setState({ isOpenAttachment: false })}
      />
      <ShowCustomList

        open={this.state.isOpenList}
        selectedArrival={this.state.selectedArrival}
        onClose={() => {
          this.setState({ isOpenList: false })
        }}
      />
      <PaginationFilterTableArrival
        hideNotificationButton={true}
        openFiltersCollaps={true}
        showEditColumns
        user={this.props.user}
        onFilterHide={() => {
          this.setState({
            taskStatusFilter: "undefined",
            sealedFilter: "undefined"
          });
        }}
        style={{
          maxHeight: this.state.tableSize.height,
          maxWidth: this.state.tableSize.width,
          minHeight: "120px"
        }}
        ref={this.tableRef}
        autoRefreshEnabled={this.state.autoRefreshEnabled}
        autoRefreshInterval={60000}
        get={poweryardArrivalHistory}
        successCallback={(response) => {
          this.setState({ hasResults: response.totalElements > 0, previousSearchRequest: response.request });
        }}
        stateStorage={"poweryard-arrival-history"}
        defaultSorted={[]}

        onFilteredChange={(filter) => {
          let psr = { ...this.state.previousSearchRequest };
          psr.filtered = filter;
          this.setState({ previousSearchRequest: psr, typeFilterField: 'All' }, () => { this.tableRef.current.requestPageSearch() });
        }}
        filtered={this.state.previousSearchRequest.filtered}
        // baseFilter={[
        //     { id: 'OrganizationUuid', value: this.props.user.company ? this.props.user.company.uuid : "null" }
        // ]}
        clearFilter={(resetAll) => {
          if (resetAll) {
            let psr = { ...this.state.previousSearchRequest };
            psr.sorted = [];
            psr.filtered = [
            { id: 'OrganizationUuid', value: this.props.user.company ? this.props.user.company.uuid : "null" },
            { id: 'LocationUuid', value: this.props.currentLocation ? this.props.currentLocation.uuid : "null" },
            { id: "start",  value: startOfDay(new Date(), 7), operator: "GT" },
            { id: "end", value: endOfDay(new Date()), operator: "LT" },
            { id: "dateFilterField", value: "createdDate", operator: "EQ" }];
            // this.taskStatusFilterRef.current.value = undefined;
            // this.sealedFilterRef.current.value = undefined;
            this.setState({
              dateFilterEnabled: false, eventstatus: true,
              previousSearchRequest: psr, taskStatusFilter: undefined,
              autoRefreshEnabled: false,
              arrivalPlanFilter: true,
              arrivalStatusFilter: true,
              departureStatusFilter: true,
              departurePlanFilter: true,
              eventStatusFilter: true,
              typeFilterField: 'All'
            });
            this.tableRef.current.changeAutoRefresh();
          } else {
            let psr = { ...this.state.previousSearchRequest };
            psr.filtered = psr.filtered.filter((f) => f.id === 'start' || f.id === 'end' || f.id === 'dateFilterField'|| f.id === 'LocationUuid');

            // this.taskStatusFilterRef.current.value = undefined;
            // this.sealedFilterRef.current.value = undefined;
            this.setState({
              previousSearchRequest: psr, arrivalPlanFilter: true,
              departureStatusFilter: true,
              departurePlanFilter: true,
              arrivalStatusFilter: true, eventStatusFilter: true, taskStatusFilter: undefined, autoRefreshEnabled: false
            }, () => this.tableRef.current.requestPageSearch());
            this.tableRef.current.changeAutoRefresh();
          }
        }}
        columns={this.tableColumns}
        onChange={(state) => {
          this.setState({
            previousSearchRequest: {
              dateFilter: state.dateFilter,
              itemsPerPage: state.itemsPerPage,
              page: state.page,
              pageSize: state.pageSize,
              sorted: state.sorted,
              filtered: state.filtered
            }
          });
        }}
        footerButton={
          <Button disabled={!this.state.hasResults} color="secondary" onClick={() => this.exportGateHistory()}><i className="fa fa-file-excel" /> Export to Excel</Button>
        }
      >
        <TableDateRangePicker
          showTaskSelector={true}
          start={this.state.filterStartDate}
          end={this.state.filterEndDate}
          filterEnabled={this.state.dateFilterEnabled}
          dateType={this.state.filterDateType}
          hisotryPageType={"gate-history"}
          onDateFilterEnable={(enabled) => {
            this.setState({ dateFilterEnabled: enabled });
            if (enabled) {
              this.updateProperty("start", this.state.filterStartDate);
              this.updateProperty("end", this.state.filterEndDate);
              this.updateProperty("dateFilterField", this.state.filterDateType);
            } else {
              this.removeFilter("start");
              this.removeFilter("end");
              this.removeFilter("dateFilterField");
            }
            this.tableRef.current.requestPageSearch();
          }}


          onStartDateChange={(date) => {
            this.updateProperty("start", date);
            this.setState({ filterStartDate: date });
            this.tableRef.current.requestPageSearch();

          }}
          onEndDateChange={(date) => {
            this.updateProperty("end", date);
            this.setState({ filterEndDate: date });
            this.tableRef.current.requestPageSearch();

          }}
          onDateTypeChange={(val) => {
            this.updateProperty("dateFilterField", val);
            this.setState({ filterDateType: val });
            this.tableRef.current.requestPageSearch();

          }}
        />
      </PaginationFilterTableArrival>
    </div>
  }
  removeFilter(id) {
    let previousSearchRequest = Object.assign({}, this.state.previousSearchRequest);
    let filtered = previousSearchRequest.filtered;
    if (filtered == null) {
      filtered = [];
    }
    for (let i = 0; i < filtered.length; i++) {
      if (filtered[i].id === id) {
        filtered.splice(i, 1);
        break;
      }
    }
    previousSearchRequest.filtered = filtered;
    this.setState({ previousSearchRequest: previousSearchRequest }, () => { this.savePageState() })
  }
  updateProperty(id, value) {
    let previousSearchRequest = Object.assign({}, this.state.previousSearchRequest);
    let filtered = previousSearchRequest.filtered;
    let found = false;
    if (filtered == null) {
      filtered = [];
    }
    for (const filter of filtered) {
      if (filter.id === id) {
        filter.value = value;
        found = true;
      }
    }
    previousSearchRequest.filtered = filtered;
    if (!found) {
      if (id === 'start') {
        filtered.push({ id: id, value: value, operator: 'GT' })
      } else if (id === 'end') {
        filtered.push({ id: id, value: value, operator: 'LT' })
      } else if (id === "filterDateType") {
        filtered.push({ id: id, value: value, operator: 'EQ' })
      } else if (id === "LocationUuid") {
        filtered.push({ id: id, value: value, operator: 'EQ' })
      }
      else {
        filtered.push({ id: id, value: value, operator: 'EQ' })
      }

    }

    this.setState({
      previousSearchRequest: previousSearchRequest
    }, () => {
      this.savePageState();

    }
    );
  }
  getProperty(field) {
    let previousSearchRequest = Object.assign({}, this.state.previousSearchRequest);
    let filtered = previousSearchRequest.filtered;
    if (filtered) {
      for (const filter of filtered) {
        if (filter && field) {
          if (filter.id === field) {
            return filter.value;
          }
        }
      }
    }

  }
  exportGateHistory() {
    if (this.state.previousSearchRequest) {
      // const utcTimestamp = new Date().getTime();
      // var zone = moment.tz.zone(this.props.currentLocation && this.props.currentLocation.timezone);
      // const offset = zone.parse(utcTimestamp);
      // console.log("offset", offset);
      let obj = this.state.previousSearchRequest;
      // obj.offset = offset;
      requestGateHistoryToExcel(obj, (res) => {
        let a = res.data;
        if (a && a.includes("https://stprde4igitblobstorage.blob.core.windows.net")) {
          window.location.href = a;
        } else {
          showError("We are not able to generate the excel file at this time.  Please try again later.");
        }
        showInfo("Successfully generated the excel and downloaded.");
      }, (e) => {
        console.log(e);
        showError("We are not able to generate the excel file at this time.  Please try again later.");
      });
    }
  }
}
ArrivalHistory.propTypes = {
  user: PropTypes.object,
}
export default ArrivalHistory;