import React, { Component } from "react";
import { CardHeader, Col, Form, Modal, ModalBody, ModalHeader, Row, Collapse } from "reactstrap";
import Switch from "react-switch";
import ReactTable from "react-table";
import { filterCaseInsensitiveContains, getLocalStorageName, restoreTableState, saveTableState } from "./utilities";
import { Pagination } from "./Pagination";
import * as PropTypes from "prop-types";
import { ITEMS_PER_PAGE_DEFAULT } from "./constants";
import Container from "reactstrap/lib/Container";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import { RefreshReload } from "./RefreshReload";
import { showError } from "../MainApp";
import Button from "reactstrap/lib/Button";
import FFFormCheckboxField from "./formik-free/FFFormCheckboxField";
import NotificationModal from "../PowerYard/components/NotificationModal";
import { getNotification, AddEventNotificationSubscriber } from "../NetworkHelpers";

class EditColumns extends Component {

  moveCol(index, destinationIndex) {
    let cols = this.props.columns;

    // let check = cols.findIndex(it=> it.hideFirst === true);
    // if(check && check === index){

    // }


    let swap = cols.splice(index, 1)[0];
    cols.splice(destinationIndex, 0, swap);
    this.checkDisable();
    this.props.onChange && this.props.onChange(cols);

  }
  checkDisable() {
    this.props.columns.map(it => it.hideFirst = false);
    let v = this.props.columns.findIndex(it => it.disableControls === true);
    if (v > -1) {
      this.props.columns[v + 1].hideFirst = true;
    } else {
      if (this.props.columns.length > 0) {
        this.props.columns[0].hideFirst = true;
      }
    }
  }
  render() {
    this.checkDisable();
    return <>
      <div>
        <Modal isOpen={this.props.open} size={'lg'}>
          <ModalHeader>
            Edit Columns
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => {
              this.onSubmit();
              e.preventDefault();
            }}>
              <Card className="simple-card">
                <CardHeader></CardHeader>
                <CardBody>
                  <Container>
                    {this.props.columns.map((col, index) => {

                      return <>
                        <Row className={col.hidden ? col.hidden === true ? "d-none" : "" : ""}>
                          <Col sm={1}>
                            {col.disableControls === undefined && col.hideFirst !== true &&
                              <Button onClick={(e) => {
                                e.preventDefault();
                                this.moveCol(index, --index);
                              }}><i className={"fa fa-arrow-up"} /></Button>}
                          </Col>
                          <Col sm={1}>
                            {index !== this.props.columns.length - 1 && col.disableControls === undefined &&
                              <Button onClick={(e) => {
                                e.preventDefault();
                                this.moveCol(index, ++index);
                              }}><i className={"fa fa-arrow-down"} /></Button>}
                          </Col>
                          <Col className={"text-left"} sm={4}>
                            {col.disableControls !== undefined &&
                              <FFFormCheckboxField
                                value={col.show}
                                disabled
                                onChange={(show) => {
                                  col.show = show;
                                  this.props.onChange && this.props.onChange(this.props.columns);
                                }}
                              />
                            }
                            {col.disableControls == undefined &&
                              <FFFormCheckboxField
                                value={col.show}
                                onChange={(show) => {
                                  col.show = show;
                                  this.props.onChange && this.props.onChange(this.props.columns);
                                }}
                              />
                            }
                          </Col>
                          <Col sm={6}>
                            {col.assetId ? this.props.assetId : col.cleanHeader} {col.default ? "*" : ""}
                          </Col>
                        </Row>

                      </>;
                    })}
                  </Container>
                </CardBody>
              </Card>
              <div className="mb-3 text-right" style={{ marginTop: "5px" }}>
                <Button color="danger" style={{ marginRight: "5px" }} onClick={this.props.onCancel}
                  type="submit">Cancel</Button>
                <Button color="secondary" style={{ marginRight: "5px" }} onClick={this.props.onRestoreDefault} type="submit">Restore Defaults</Button>
                <Button color="primary" onClick={this.props.onClose}>Save</Button> &nbsp;
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </>;
  }
}

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationData: [],
      enabledNotification: this.props.enabledNotification,
      company: this.props
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    getNotification((success) => {
      this.setState({ notificationData: success.data })
    })

  }
  
  updateEnabledNotificationsStatus() {
    this.setState({ enabledNotification: [] });
  }
  render() {

    return <>
      <div>
        <Modal isOpen={this.props.open} size={'lg'}>
          <ModalHeader>
            Notification
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => {
              this.onSubmit();
              e.preventDefault();
            }}>
              <Card className="simple-card">
                <CardHeader></CardHeader>
                <CardBody>
                  <Container>
                    {this.state.notificationData.map((event, index) => {

                      return <>
                        <Row className={event.hidden ? event.hidden === true ? "d-none" : "" : ""}>
                          <Col className={"text-left"} sm={4}>
                            <FFFormCheckboxField
                              value={this.state.enabledNotification.filter(e => e === this.props.locationUuid + '-' + event.eventCode).length > 0}
                              onChange={(show) => {
                                let eventId = event.eventCode;
                                eventId = this.props.locationUuid + '-' + eventId;
                                let s = [...this.state.enabledNotification];
                                if (show == true) {
                                  s.push(eventId);
                                } else {
                                  s.splice(s.findIndex(it => it == eventId), 1);
                                }
                                this.setState({ enabledNotification: s });

                                // if (this.state.enabledNotification.filter(e => e === this.props.company+'-'+col.eventCode).length > 0) {
                                //     this.setState({
                                //         enabledNotification:
                                //             this.state.enabledNotification.filter(function (item) {
                                //                 return item !== this.props.company+'-'+col.eventCode
                                //             })
                                //     });
                                // } else {
                                //     this.setState({
                                //         enabledNotification: [
                                //             ...this.state.enabledNotification,
                                //             this.props.company+'-'+col.eventCode]
                                //     });
                                // }


                              }}
                            />
                          </Col>
                          <Col sm={6}>
                            {event.name}
                          </Col>
                        </Row>

                      </>;
                    })}
                  </Container>
                </CardBody>
              </Card>
              <div className="mb-3 text-right" style={{ marginTop: "5px" }}>
                <Button color="danger" style={{ marginRight: "5px" }} onClick={this.props.onCancel}
                  type="submit">Cancel</Button>
                <Button color="primary" onClick={() => { this.props.onSave && this.props.onSave(this.state.enabledNotification) }}>Save</Button> &nbsp;
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </>;
  }
}

class NotificationMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationData: [],
      enabledNotification: []
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    getNotification((success) => {
      this.setState({ notificationData: success.data })
    })

  }

  render() {
    return <>
      <div>
        <Modal isOpen={this.props.open} size={'lg'}>
          <ModalHeader>
            Edit Columns
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => {
              this.onSubmit();
              e.preventDefault();
            }}>
              <Card className="simple-card">
                <CardHeader></CardHeader>
                <CardBody>
                  <Container>
                    {this.state.notificationData.map((col, index) => {

                      return <>
                        <Row className={col.hidden ? col.hidden === true ? "d-none" : "" : ""}>

                          <Col sm={6}>
                            {col.name}
                          </Col>
                        </Row>

                      </>;
                    })}
                  </Container>
                </CardBody>
              </Card>
              <div className="mb-3 text-right" style={{ marginTop: "5px" }}>
                <Button color="danger" style={{ marginRight: "5px" }} onClick={this.props.onCancel}
                  type="submit">Cancel</Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </>;
  }
}


export class PaginationFilterTableArrival extends Component {
  constructor(props) {
    super(props);
    let localStorageState = null;
    this.notifiationRef = React.createRef();
    if (props.stateStorage) {
      localStorageState = JSON.parse(localStorage.getItem(getLocalStorageName(props.stateStorage + '-pft', props.user)));
    }
    if (props.pageType !== "Admin" && props.pageType !== "poweryard-settings" && props.pageType !== "assets-history") {
      if (localStorageState === null) {
        this.restoreColumns();
        localStorageState = JSON.parse(localStorage.getItem(getLocalStorageName(props.stateStorage + '-pft', props.user)));
      }
    }
    if (localStorageState != null) {
      this.state = restoreTableState(localStorageState);
      if (localStorageState.columns !== undefined) {
        let fullColumns = [];
        if (props.columns && (localStorageState.columns.length !== props.columns.length)) {
          this.state.columns = props.columns;
        } else {
          for (const storedColumn of localStorageState.columns) {
            if (props.columns) {
              for (const propColumn of props.columns) {
                if (storedColumn.accessor === propColumn.accessor) {
                  propColumn.show = storedColumn.show;
                  fullColumns.push(propColumn);
                }
              }
            }
          }
          this.state.columns = fullColumns;
        }
      }
      if (localStorageState.enabledNotification !== undefined) {
        if (props.enabledNotification && (localStorageState.enabledNotification.length !== props.enabledNotification.length)) {
          this.state.enabledNotification = props.enabledNotification;
        }
      }

    } else {
      this.state = {
        page: props.page ? props.page : 0,
        itemsPerPage: props.itemsPerPage ? props.itemsPerPage : ITEMS_PER_PAGE_DEFAULT,
        pageSize: props.itemsPerPage ? props.itemsPerPage : ITEMS_PER_PAGE_DEFAULT,
        hideFilters: props.hideFilters != undefined ? props.hideFilters : true,
        sorted: props.sorted ? props.sorted : props.defaultSort ? props.defaultSort : [],
        filtered: props.filtered ? props.filtered : props.baseFilter ? props.baseFilter : [],
        pageCount: 0,
        autoRefreshEnabled: true,
        filterOpen: props.openFilters,
        columns: props.columns,
        enabledNotification: props.enabledNotification,
        socketState: [],
        connected: '',
        socketUrl: '',
        connectionID: null,
      };
    }
    // autoRefreshInterval: props.autoRefreshInterval ? props.autoRefreshInterval : 20000,
    // if (props.autoRefreshEnabled === undefined || props.autoRefreshEnabled === null ) {
    //     this.state.autoRefreshEnabled = false;
    // }
    if (props.openFilters) {
      this.state.filterOpen = false;
    }
    this.searchInterval = setInterval(() => {
      if (this.state.autoRefreshEnabled) {
        this.fetchData();
      }
    },
      this.props.autoRefreshInterval ? this.props.autoRefreshInterval : 20000
    );
    if (this.state.dateFilters == null) {
      this.state.dateFilters = new Map();
    }


    if (!this.state.columns) {
      this.state.columns = props.columns;
    }
    if (!this.state.enabledNotification) {
      this.state.enabledNotification = props.enabledNotification;
    }

    this.state.columns = this.state.columns.map((col) => {
      let x = col.Header;
      if (col.show === undefined) {
        col.show = true;
      }
      if (!col.cleanHeader) {
        col.cleanHeader = x;
      }

      if (col.sortable) {
        col.Header = (obj) => {
          if (typeof x === 'function') {
            return (
              <div title={col.title}><i className="fa fa-sort" title={col.title} /> {x()}</div>
            );
          } else {
            return (
              <div title={col.title}><i className="fa fa-sort" title={col.title} /> {x}</div>
            );
          }
        }
      }

      if (col.title && !col.sortable) {
        col.Header = (obj) => {
          if (typeof x === 'function') {
            return (
              <div title={col.title}>{x()}</div>
            );
          } else {
            return (
              <div title={col.title}>{x}</div>
            );
          }
        }
      }

      return col;
    })


  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchData();
    this.setState({autoRefreshEnabled: true});
    // if (!this.state.connected) {
    //     fetch('https://prd-e4-igit-driverappapi.azurewebsites.net/api/AppRun/GetPubSubLinkWWS')
    //         .then(response => response.json())
    //         .then(data => {
    //             this.setState({socketUrl: data.data})
    //             const connection = new WebSocket(this.state.socketUrl, 'json.webpubsub.azure.v1')
    //             this.setState({connected: connection})

    //             this.state.connected.onopen = this.SocketConnectionOpen;

    //             this.state.connected.onmessage = this.SocketConnectionOnMessage;
    //             console.log(this.state.connected.onmessage.connectionID)
    //             this.setState({connectionID: this.state.connected.onmessage.connectionID });
    //         });
    // }
    // if(this.state.socketUrl !== '' && this.state.socketUrl !== undefined){
    //     console.log(this.state.socketUrl, 'socket')
    //     const connection = new WebSocket(this.state.socketUrl, 'json.webpubsub.azure.v1')
    //     this.setState({connected:connection})
    // }
    // if (this.state.connected) {
    //     this.state.connected.onopen = this.SocketConnectionOpen;
    //
    //     this.state.connected.onmessage = this.SocketConnectionOnMessage;
    // }
  }

  SocketConnectionOpen(e) {
    console.log(e)
  }

  // SocketConnectionOnMessage(evt) {
  //     let messages = evt.data;
  //     let connectionID = JSON.parse(messages).connectionId;
  //     console.log(evt, messages, 'mess', connectionID);
  //     if(connectionID) {
  //         getConnectionIDPostRequest({
  //             "connectionid": connectionID,
  //             "lstGroups": [
  //                 "lahore-2",
  //                 "lahore-3",
  //                 "lahore-4",
  //             ]
  //         })
  //     }
  // }


  // prevent search on every key stroke.
  requestPageSearch() {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.searchTimer = setTimeout(() => {
      this.searchTimer = false;
      this.fetchData();
    }, 500);
  }
  refreshColumns() {
    let localStorageState = null;
    let cols = [];
    localStorageState = JSON.parse(localStorage.getItem(getLocalStorageName(this.props.stateStorage + '-pft', this.props.user)));
    if (localStorageState != null) {
      // this.state = restoreTableState(localStorageState);
      if (localStorageState.columns !== undefined) {
        let fullColumns = [];
        localStorageState.columns[0].show = this.props.columns[0].show;
        if (this.props.columns && (localStorageState.columns.length !== this.props.columns.length)) {
          this.setState({
            columnsD: this.props.columns
          });
        } else {
          for (const storedColumn of localStorageState.columns) {
            if (this.props.columns) {
              for (const propColumn of this.props.columns) {
                if (storedColumn.accessor === propColumn.accessor) {
                  propColumn.show = storedColumn.show;
                  fullColumns.push(propColumn);
                }
              }
            }
          }
          this.setState({

            columnsD: fullColumns
          });
        }
      }
    } else {
      this.setState({
        columnsD: this.props.columns
      });
    }

    this.setState({ columns: this.state.columnsD }, () => {
      saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
    })


    // this.setState({columns: this.state.columnsD }, () => {
    //     // this.fetchData();
    //     saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
    // })


  }

  fetchData() {
    let filters = this.props.filtered ? this.props.filtered : this.state.filtered;
    this.props.baseFilter && this.props.baseFilter.forEach((f) => {
      if (filters.filter((e) => { return e.id === f.id }).length === 0) {
        filters = filters.concat(f); // add if not already found
      } else { // update in case value changed by removing then concatenating
        filters = filters.filter((e) => { return e.id !== f.id }).concat(f);
      }
    });
    let searchRequest = {
      page: this.state.page,
      pageSize: this.state.pageSize,
      sorted: this.props.sorted ? this.props.sorted : this.state.sorted,
      filtered: filters,
    };
    this.props.additionalSearchCriteria && Object.assign(searchRequest, this.props.additionalSearchCriteria);
    let integerColumns = this.props.columns.filter(it => it.integerInput === true);
    let searchAllow = false;
    if (integerColumns.length > 0) {
      for (let index = 0; index < filters.length; index++) {
        if (filters.length > 0) {
          let obj = integerColumns.find(it => it.accessor === filters[index].id);
          if (obj) {
            let val = filters[index].value;
            if (isNaN(val)) {
              showError("This field must be an integer");
              break;
            } else if (val.includes(".") === true) {
              showError("This field must be an integer");
              break;
            }
          }
        }
        if (index === filters.length - 1) {
          searchAllow = true;
        }

      }

      if (searchAllow === true) {
        this.props.get(searchRequest,
          (res) => {
            if (res.code === '200') {
              let success = res.content;
              this.setState({
                pages: success.totalPages,
                tableData: success.results,
                loading: false,
                pageCount: success.totalPages
              });
              success.request = searchRequest;
              this.props.successCallback && this.props.successCallback(success)
              if (this.state.page > success.totalPages) {
                this.setState({ page: 0, forcePage: 0 });
              }
            } else {
              showError("Could not load data");
              this.setState({
                loading: false,
                tableData: []
              });

            }
          },
          (failure) => {
            if (!failure.hideToast) {
              showError("Could not load data");
            }
            this.props.failureCallback && this.props.failureCallback(failure)
          });
      }
    } else {
      this.props.get(searchRequest,
        (res) => {
          if (res.code === '200') {
            let success = res.content;
            this.setState({
              pages: success.totalPages,
              tableData: success.results,
              loading: false,
              pageCount: success.totalPages
            });
            success.request = searchRequest;
            this.props.successCallback && this.props.successCallback(success)
            if (this.state.page > success.totalPages) {
              this.setState({ page: 0, forcePage: 0 });
            }
          } else {
            this.setState({
              loading: false,
              tableData: []
            });
            showError("Could not load data");
          }
        },
        (failure) => {
          if (!failure.hideToast) {
            showError("Could not load data");
          }
          this.props.failureCallback && this.props.failureCallback(failure)
        });

    }
  }

  componentWillUnmount() {
    saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
    clearInterval(this.searchInterval);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
   
      console.log("snapshotA",this.state.autoRefreshEnabled,this.props.autoRefreshEnabled);
     
    if (!this.arraysAreEqual(this.props.baseFilter, prevProps.baseFilter) ||
      this.props.additionalSearchCriteria !== prevProps.additionalSearchCriteria) {
      this.fetchData();
    }

  }
  updateEnabledNotificationsStatus() {
    this.setState({ enabledNotification: [] });
    this.notifiationRef.current.updateEnabledNotificationsStatus();
  }
  arraysAreEqual(l, r) {
    if (l === r) {
      return true;
    }
    if (!r || !l) {
      return false;
    }
    if (l.length !== r.length) {
      return false;
    }
    for (let i = 0; i < l.length; i++) {
      if (!this.objectAreEqual(l[i], r[i])) {
        return false;
      }
    }
    return true;
  }
  objectAreEqual(l, r) {
    let lProps = Object.getOwnPropertyNames(l);
    let rProps = Object.getOwnPropertyNames(r);
    if (lProps.length !== rProps.length) {
      return false;
    }
    for (let i = 0; i < lProps.length; i++) {
      let lPropName = lProps[i];
      if (l[lPropName] !== r[lPropName]) {
        return false;
      }
    }
    return true;
  }
  render() {
    if (!this.state.tableData) {
      return <div className="column-wrapper">
        <Modal isOpen={this.state.areYouSureOpen}>
          <ModalBody>
            <Row>
              <Col>
                <h3>Reset to default table view?</h3>
              </Col>
            </Row>
            <Row className={"text-right"}>
              <Col>
                <Button color="secondary" onClick={() =>
                  this.setState({
                    areYouSureOpen: false,
                  })
                }>No</Button>
                <Button color="primary" type="submit"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    this.setState({
                      areYouSureOpen: false,
                      hideFilters: true,
                      sorted: this.props.defaultSorted ? this.props.defaultSorted : [],
                      filtered: this.props.baseFilter ? this.props.baseFilter : [],
                      page: 0,
                      forcePage: 0,
                    }, () => {
                      this.requestPageSearch();
                      this.props.onChange && this.props.onChange(this.state);
                      this.props.clearFilter && this.props.clearFilter(true);
                    })
                  }}>Yes</Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <EditColumns
          assetId={(this.props.user && this.props.user.company && this.props.user.company.shipmentIdDisplay) || "Asset ID"}
          open={this.state.editColumnsOpen}
          columns={this.state.editColumns || []}
          onChange={(cols) => {
            this.setState({
              editColumns: cols,

            });
          }}
          onRestoreDefault={(e) => {
            e.preventDefault();
            this.restoreColumns();
          }}
          onCancel={(e) => {
            e.preventDefault();
            this.setState({ editColumnsOpen: false, columns: this.state.columns }, () => {
              this.fetchData();
              saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
            })
          }}
          onClose={() => {
            this.setState({
              editColumnsOpen: false,
              columns: this.state.editColumns,

            },
              () => {
                this.fetchData();
                saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
              })
          }} />
        {/* Change For Deployment */}
        <Notification
          locationUuid={this.props.locationUuid}
          ref={this.notifiationRef}
          open={this.state.notificationsOpen}
          enabledNotification={this.state.enabledNotification || []}
          onSave={(enabled) => {
            let obj = {
              uuid: this.props.user.uuid,
              locationUUID: this.props.locationUuid,
              sJson: JSON.stringify(enabled)
            };
            AddEventNotificationSubscriber(obj, (res) => {
              console.log(res);
              localStorage.setItem(getLocalStorageName("enabledNotification", this.props.user), JSON.stringify(enabled));
              this.setState({ enabledNotification: enabled, notificationsOpen: false });
              this.props.enabledNotificationsCallBack && this.props.enabledNotificationsCallBack(enabled);
            })
          }}
          onRestoreDefault={(e) => {
            e.preventDefault();
            this.restoreColumns();
          }}
          onCancel={(e) => {
            e.preventDefault();
            this.setState({
              notificationsOpen: false,
              enabledNotification: this.state.enabledNotification
            }, () => {
              // this.fetchData();
              // saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
            })
          }}
          onClose={() => {
            let s = { ...this.state.enabledNotification };
            this.setState({
              notificationsOpen: false,
              enabledNotification: this.state.enabledNotification
            }, () => {
              console.log(this.state.connectionID, this.state.enabledNotification, 'id not')
              // this.state.connected.onmessage= (e) => {
              //     let messages = e.data;
              //     let connectionID = JSON.parse(messages).connectionId;
              //     getConnectionIDPostRequest({
              //         "connectionid": connectionID,
              //         "lstGroups": this.state.enabledNotification
              //     })
              // };
              saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
            })
          }} />
        <NotificationMessage
          open={this.state.notificationMessageOpen}
          // enabledNotification={this.state.enabledNotification || []}

          onCancel={(e) => {
            e.preventDefault();
            this.setState({ notificationMessageOpen: false }, () => {
            })
          }} />
        <Collapse isOpen={this.props.openFiltersCollaps}>
          <div className="table-action-bar page-header d-none" id="pft-action-bar-header" style={
            this.props.hideFilter && { borderTop: 0 }
          }>
            <Row>
              <Col sm="7">
                {this.props.children}
              </Col>
              <Col sm="5" className="filter-box">
                <div className="flex-grow-1" />
                {this.props.hideNotificationButton !== true &&
                  <Button className={this.state.enabledNotification && this.state.enabledNotification.length > 0 ? 'btn-success' : 'btn-secondary'} style={{ marginLeft: "5px" }} onClick={() => {
                    let cols = [];
                    let s = [...this.state.columns];
                    s.forEach((column) => {
                      let clone = {};
                      for (let prop in column) {
                        if (column.hasOwnProperty(prop)) {
                          clone[prop] = column[prop];
                        }
                      }
                      cols.push(clone);
                    })
                    this.setState({ notificationsOpen: true, editColumns: cols })
                  }}><i className={this.state.enabledNotification && this.state.enabledNotification.length > 0 ? 'fas fa-bell-on' : 'fa fa-bell'} /></Button>
                }
                {this.props.hideNotificationButton !== true && this.state.enabledNotification && this.state.enabledNotification.length > 0 &&
                  <span className="button__badge">{this.state.enabledNotification && this.state.enabledNotification.length}</span>
                }

                {/* <Button style={{marginLeft: "5px"}} onClick={() => {
                                    this.setState({notificationMessageOpen: true})
                                }}><i className="fa fa-bell"/></Button> */}
                {this.props.additionalFilter}
                {this.props.showEditColumns &&
                  <Button style={{ marginLeft: "5px" }} onClick={() => {
                    let cols = [];
                    let s = [...this.state.columns];
                    s.forEach((column) => {
                      let clone = {};
                      for (let prop in column) {
                        if (column.hasOwnProperty(prop)) {
                          clone[prop] = column[prop];
                        }
                      }
                      cols.push(clone);
                    })
                    this.setState({ editColumnsOpen: true, editColumns: cols })
                  }}>Columns</Button>
                }
                <div className="filter-switch ml-3" title="Show Filter">
                  {!this.props.hideFilter && <div>
                    <label>Show Filter</label>
                    <Switch
                      onChange={(checked) => {
                        this.setState({ hideFilters: !checked }, () => {
                          if (this.props.filtered) {
                            this.props.onChange && this.props.onChange(this.state);
                            this.props.clearFilter && this.props.clearFilter();
                          } else {
                            if (this.state.hideFilters) {
                              this.setState({
                                filtered: this.props.baseFilter
                                  ? this.props.baseFilter : []
                              }, () => {
                                this.props.onChange && this.props.onChange(this.state);
                                this.props.clearFilter && this.props.clearFilter();
                              })
                            } else {
                              let filters = this.state.filtered;
                              this.state.dateFilters.forEach((f) => {
                                filters.push({ id: f.id, value: f.value, operator: f.operator });
                              });
                              this.setState({ filtered: filters }, () => this.requestPageSearch());
                              this.props.onChange && this.props.onChange(this.state);
                            }
                          }
                        });
                      }}
                      checked={!this.state.hideFilters}
                      uncheckedIcon={<div className="text-center pt-1"><i className="fa fa-filter" /></div>}
                      checkedIcon={<div className="text-center pt-1"><i className="fa fa-filter" /></div>}
                      title="Show Filter"
                      className="react-switch" />
                  </div>}
                </div>
                {!this.props.hideClear &&
                  <Button onClick={() => {
                    this.setState({
                      areYouSureOpen: true,
                    })
                  }} color='warning'>  </Button>}
              </Col>
            </Row>
          </div>
        </Collapse>
        <div className={'app align-items-center animated fadeIn ' + this.props.hideTableForAccessDenied && this.props.hideTableForAccessDenied === false ? "d-none" : "d-block"} >
          <Container>
            <Row className="justify-content-center">
              <Col md="12">
                <Card className="text-white bg-primary py-5 d-md-down-none" >
                  <CardBody className="text-center">
                    <div>
                      {/* <h2>Please wait !! Loading Data, it will take time due to higher number of records...</h2> */}
                      <h2>Loading Data...</h2>
                      <div className="sk-wave">
                        <div className="sk-rect sk-rect1" />&nbsp;
                        <div className="sk-rect sk-rect2" />&nbsp;
                        <div className="sk-rect sk-rect3" />&nbsp;
                        <div className="sk-rect sk-rect4" />&nbsp;
                        <div className="sk-rect sk-rect5" />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>



      </div>;
    } else {
      return <div className="column-wrapper">
        <Modal isOpen={this.state.areYouSureOpen}>
          <ModalBody>
            <Row>
              <Col>
                <h3>Reset to default table view?</h3>
              </Col>
            </Row>
            <Row className={"text-right"}>
              <Col>
                <Button color="secondary" onClick={() =>
                  this.setState({
                    areYouSureOpen: false,
                  })
                }>No</Button>
                <Button color="primary" type="submit"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    this.setState({
                      areYouSureOpen: false,
                      hideFilters: true,
                      sorted: this.props.defaultSorted ? this.props.defaultSorted : [],
                      filtered: this.props.baseFilter ? this.props.baseFilter : [],
                      page: 0,
                      forcePage: 0,
                    }, () => {
                      this.requestPageSearch();
                      this.props.onChange && this.props.onChange(this.state);
                      this.props.clearFilter && this.props.clearFilter(true);
                    })
                  }}>Yes</Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <EditColumns
          assetId={(this.props.user && this.props.user.company && this.props.user.company.shipmentIdDisplay) || "Asset ID"}
          open={this.state.editColumnsOpen}
          columns={this.state.editColumns || []}
          onChange={(cols) => {
            this.setState({ editColumns: cols });
          }}
          onRestoreDefault={(e) => {
            e.preventDefault();
            this.restoreColumns();
          }}
          onCancel={(e) => {
            e.preventDefault();
            this.setState({ editColumnsOpen: false, columns: this.state.columns }, () => {
              this.fetchData();
              saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
            })
          }}
          onClose={() => {
            let s = { ...this.state.editColumns };
            this.setState({ editColumnsOpen: false, columns: this.state.editColumns }, () => {
              // this.fetchData();
              saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
            })
          }} />
        <Notification
          locationUuid={this.props.locationUuid}
          open={this.state.notificationsOpen}
          ref={this.notifiationRef}
          enabledNotification={this.state.enabledNotification || []}
          onSave={(enabled) => {
            let obj = {
              uuid: this.props.user.uuid,
              locationUUID: this.props.locationUuid,
              sJson: JSON.stringify(enabled)
            };
            AddEventNotificationSubscriber(obj, (res) => {
              console.log(res);

              localStorage.setItem(getLocalStorageName("enabledNotification", this.props.user), JSON.stringify(enabled));
              this.setState({ enabledNotification: enabled, notificationsOpen: false });
              this.props.enabledNotificationsCallBack && this.props.enabledNotificationsCallBack(enabled);
            })
          }}
          onCancel={(e) => {
            e.preventDefault();
            this.setState({
              notificationsOpen: false,
              enabledNotification: this.state.enabledNotification
            }, () => {
              // this.fetchData();
              saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
            })
          }}
          onClose={() => {
            let s = { ...this.state.enabledNotification };
            this.setState({
              notificationsOpen: false,
              enabledNotification: this.state.enabledNotification
            }, () => {
              console.log(this.state.connectionID, this.state.enabledNotification, 'id not')
              // this.state.connected.onmessage= (e) => {
              //     let messages = e.data;
              //     let connectionID = JSON.parse(messages).connectionId;
              //     getConnectionIDPostRequest({
              //         "connectionid": connectionID,
              //         "lstGroups": this.state.enabledNotification
              //     })
              // };

              saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
            })
          }} />
        <NotificationMessage
          open={this.state.notificationMessageOpen}
          // enabledNotification={this.state.enabledNotification || []}

          onCancel={(e) => {
            e.preventDefault();
            this.setState({ notificationMessageOpen: false }, () => {
            })
          }} />
        <Collapse isOpen={this.props.openFiltersCollaps}>
          <div className="table-action-bar page-header" id="pft-action-bar-header" style={
            this.props.hideFilter && { borderTop: 0 }
          }>
            <Row>
              <Col sm="7">
                {this.props.children}
              </Col>
              <Col sm="5" className="filter-box">
                <div className="flex-grow-1" />
                {this.props.hideNotificationButton !== true &&
                  <Button className={this.state.enabledNotification && this.state.enabledNotification.length > 0 ? 'btn-success' : 'btn-secondary'} style={{ marginLeft: "5px" }} onClick={() => {
                    let cols = [];
                    let s = [...this.state.columns];
                    s.forEach((column) => {
                      let clone = {};
                      for (let prop in column) {
                        if (column.hasOwnProperty(prop)) {
                          clone[prop] = column[prop];
                        }
                      }
                      cols.push(clone);
                    })
                    this.setState({ notificationsOpen: true, editColumns: cols })
                  }}><i className={this.state.enabledNotification && this.state.enabledNotification.length > 0 ? 'fas fa-bell-on' : 'fa fa-bell'} /> </Button>}
                {this.props.hideNotificationButton !== true && this.state.enabledNotification && this.state.enabledNotification.length > 0 &&

                  <span className="button__badge">{this.state.enabledNotification && this.state.enabledNotification.length}</span>
                }


                {this.props.additionalFilter}
                {this.props.showEditColumns &&
                  <Button style={{ marginLeft: "5px" }} onClick={() => {
                    let cols = [];
                    let s = [...this.state.columns];
                    s.forEach((column) => {
                      let clone = {};
                      for (let prop in column) {
                        if (column.hasOwnProperty(prop)) {
                          clone[prop] = column[prop];
                        }
                      }
                      cols.push(clone);
                    })
                    this.setState({ editColumnsOpen: true, editColumns: cols })
                  }}>Columns</Button>
                }
                <div className="filter-switch ml-3" title="Show Filter">
                  {!this.props.hideFilter && <div>
                    <label>Show Filter</label>
                    <Switch
                      onChange={(checked) => {
                        this.setState({ hideFilters: !checked }, () => {
                          if (this.state.hideFilters===false) {
                              this.props.onFilterHide()
                              }
                          if (this.props.filtered) {

                            this.props.onChange && this.props.onChange(this.state);
                            this.props.clearFilter && this.props.clearFilter();
                          } else {
                            if (this.state.hideFilters) {
                              this.setState({
                                filtered: this.props.baseFilter
                                  ? this.props.baseFilter : []
                              }, () => {
                                this.props.onChange && this.props.onChange(this.state);
                                this.props.clearFilter && this.props.clearFilter();
                              })
                            } else {
                              let filters = this.state.filtered;
                              this.state.dateFilters.forEach((f) => {
                                filters.push({ id: f.id, value: f.value, operator: f.operator });
                              });
                              this.setState({ filtered: filters }, () => this.requestPageSearch());
                              this.props.onChange && this.props.onChange(this.state);
                            }
                          }
                        });
                      }}
                      checked={!this.state.hideFilters}
                      uncheckedIcon={<div className="text-center pt-1"><i className="fa fa-filter" /></div>}
                      checkedIcon={<div className="text-center pt-1"><i className="fa fa-filter" /></div>}
                      title="Show Filter"
                      className="react-switch" />
                  </div>}
                </div>
                {!this.props.hideClear &&
                  <Button onClick={() => {
                    this.setState({
                      areYouSureOpen: true,
                    })
                  }} color='warning'> <i className="fa fa-undo" /></Button>}
              </Col>
            </Row>
          </div>
        </Collapse>
        {this.props.renderModal}
        <div className="main-table-wrapper">
          <ReactTable
            manual
            showPagination={false}
            columns={this.state.columns}
            data={this.state.tableData ? this.state.tableData : []}
            resolveData={this.props.resolveData}
            loading={this.state.loading}
            page={this.state.page}
            pageSize={this.state.tableData && this.state.tableData.length ? this.state.tableData.length : 0}
            filtered={this.props.filtered ? this.props.filtered : this.state.filtered}
            onFilteredChange={(filter) => {
              if (this.props.filtered) {
                this.setState({ page: 0, forcePage: 0 }, () => {
                  this.props.onFilteredChange && this.props.onFilteredChange(filter);
                })
              } else {
                this.setState({ filtered: filter, page: 0, forcePage: 0 }, () => {
                  this.requestPageSearch();
                });
              }
            }}
            defaultFiltered={this.state.filtered ? this.state.filtered :
              this.props.baseFilter ? this.props.baseFilter : []}
            sorted={this.getSorted()}
            onSortedChange={(sort) => {
              if (this.props.sorted) {
                this.setState({ sortedChanged: true });
                this.props.onSortedChange && this.props.onSortedChange(sort);
              } else {
                this.setState({ sorted: sort }, () => {
                  this.requestPageSearch();
                });
              }
            }}
            defaultSorted={this.props.defaultSorted ? this.props.defaultSorted : null}
            className="-striped -highlight"
            defaultFilterMethod={filterCaseInsensitiveContains}
            getTheadFilterProps={(state, rowInfo, column, instance) => {
              return {
                style:
                  this.state.hideFilters
                    ? { display: "none" }
                    : null
              };
            }}
            getTheadFilterThProps={(state, rowInfo, column, instance) => {
              if (column.dataType === 'date') {
                return {
                  style: { overflow: "inherit" }
                }
              } else {
                return {};
              }
            }}
            style={this.props.style ? this.props.style : {
              maxHeight: "100%",// This will force the table body to overflow and scroll, since there is not enough room
              minHeight: "120px"
            }}
            getTdProps={this.props.getTdProps}
            onResizedChange={this.props.onResizedChange}
          />
        </div>
        {this.props.showFooterSaveCloseButton && this.props.showFooterSaveCloseButton &&
          <div className={"table-action-bar page-footer "} id="pft-action-bar-footer">
            <Row >
              <Col md="4">
              </Col>
              <Col md="12">
                <Row>
                  <Col md={6}>
                    {this.props.footerCancelButton}
                  </Col>
                  <Col md={6}>
                    {this.props.footerSaveButton}
                  </Col>

                </Row>
              </Col>
            </Row>
          </div>
        }
        <div className="table-action-bar page-footer" id="pft-action-bar-footer">
          <Row>
            <Col md="8">

              <Row>
                <Col md="4">
                </Col>
                <Col md="12">
                  <Row>
                    <Col md={6}>
                      {this.props.footerButton}
                    </Col>
                    <Col md={6} className={"text-center"}>
                      <RefreshReload
                        checked={this.state.autoRefreshEnabled}
                        disableAutoRefresh={this.props.disableAutoRefresh && this.props.disableAutoRefresh}
                        disableAutoRefreshMsg={this.props.disableAutoRefreshMsg && this.props.disableAutoRefreshMsg}

                        onChange={(res) => {
                          let a = { ...this.state }
                          this.setState({ autoRefreshEnabled: !this.state.autoRefreshEnabled });
                          this.props.RefreshReload && this.props.RefreshReload(res)
                        }}
                        onClick={() => {
                          this.fetchData();
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col md="4">
              <div className="pagination-wrapper">
                <Pagination
                  key={this.state.itemsPerPage}
                  pageCount={this.state.pageCount}
                  initialPage={this.state.page}
                  forcePage={this.state.forcePage}
                  onPageChange={(page) => {
                    this.setState({ page: page.selected, forcePage: undefined }, () => {
                      this.fetchData();
                    });
                  }}
                  specificPageSizes={this.props.specificPageSizes}
                  itemsPerPage={this.state.itemsPerPage}
                  onItemsPerPageChange={(e) => {

                    this.setState({
                      itemsPerPage: e.target.value, page: 0,
                      pageSize: e.target.value,
                      tableData: undefined
                    }, () => {
                      this.fetchData();
                    });
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>



      </div >;
    }

  }
  changeAutoRefresh() {
    this.setState({
      autoRefreshEnabled: this.props.autoRefreshEnabled
    })
  }
   restoreColumns(directToState) {
    let cols = this.props.columns;
    for (const col of cols) {
      col.show = !!col.default;
      if (col.keepShowing && col.keepShowing === true) {
        col.show = true;
      }
    }
    if (directToState) {
      this.setState({ columns: cols }, () => {
        saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
      })
    } else {
      this.setState({ editColumns: cols }, () => {
        saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
      })
    }

  }
  
  
  getSorted() {
    let propOrState = this.props.sorted ? this.props.sorted : this.state.sorted;
    if (!propOrState) return [];
    return propOrState;
  }
}
PaginationFilterTableArrival.propTypes = {
  onChange: PropTypes.func,
  renderModal: PropTypes.any,
  columns: PropTypes.arrayOf(PropTypes.any),
  baseFilter: PropTypes.array,
  additionalSearchCriteria: PropTypes.object,
  stateStorage: PropTypes.string,
  page: PropTypes.number,
  itemsPerPage: PropTypes.number,
  pageSize: PropTypes.number,
  hideFilter: PropTypes.bool,
  hideFilters: PropTypes.bool,
  sorted: PropTypes.arrayOf(PropTypes.object),
  defaultSorted: PropTypes.arrayOf(PropTypes.object),
  onSortedChange: PropTypes.func,
  filtered: PropTypes.arrayOf(PropTypes.object),
  defaultFiltered: PropTypes.arrayOf(PropTypes.object),
  onFilteredChange: PropTypes.func,
  autoRefreshEnabled: PropTypes.bool,
  get: PropTypes.func,
  successCallback: PropTypes.func,
  RefreshReload: PropTypes.func,
  failureCallback: PropTypes.func,
  children: PropTypes.any,
  additionalFilter: PropTypes.any,
  clearFilter: PropTypes.func,
  resolveData: PropTypes.any,
  getTdProps: PropTypes.func,
  footerButton: PropTypes.any,
  showEditColumns: PropTypes.bool,
};